import axios from "axios";

/**
 * Usado para comunicar o react com a instância Node do Next.js
 * @returns 
 */
export function getFrontendAPIClient<AxiosInstance>() {
  return axios.create({
    baseURL: "/api"
  })
}

/**
 * Usado para comunicar a instância Node do Nextjs e o Backend
 * @param ctx Contexto da requisição para pegar o cookie
 * @returns 
 */
export function getBackendAPIClient<AxiosInstance>(ctx?: any) {
  const api = axios.create({
    baseURL: process.env.API_BASE_URL
  })

  api.defaults.headers.common['Authorization'] = process.env.API_ACCESS_TOKEN || ''

  return api;
}