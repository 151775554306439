export default {
    title: "Reze pela Ucrânia",
    p1: "Em 1990, o Professor e líder católico Dr. Plinio Corrêa de Oliveira, através da TFP brasileira e entidades afins pelo mundo inteiro, promoveu um abaixo-assinado de mais de 5.000.000 de assinaturas pedindo a liberdade da Lituânia do jugo comunista. Foi o maior abaixo-assinado da História, que contribuiu para a conquista, não só da liberdade daquele país, com o do fim da URSS.",
    p2: "Hoje, o IPCO, juntamente com as entidades afins e co-irmãs de todo o mundo, promove uma campanha de orações pelos nossos irmãos católicos e por todos os ucranianos, que estão sendo novamente ameaçados pelo jugo comunista, muitos deles sacrificando a própria vida.",
    p3: "Nossa meta é alcançar 5.000.000 de Ave-Marias rezadas, em todo o mundo, pedindo a Nossa Senhora que livre a Ucrânia da guerra e do jugo comunista.",

    // Navbar
    ipco_logo: "Logo do Instituto Plinio Corrêa de Oliveira",

    //Form
    toast_error_title: "Erro ao salvar as Ave-Marias",
    toast_sucess_title: "Ave-Marias cadastradas com sucesso",
    toast_sucess_description: "Os dados serão atualizados no site em instantes",

    form_heading: "Quanto você JÁ rezou?",
    form_t1: "Preencha o formulário de acordo com as Ave-Marias que você já rezou. Volte nesse site e preencha quando você rezar mais.",
    form_t2: "Selecione o total de Ave-Marias já rezadas",

    input_1_label: "Primeiro nome",
    input_1_placeholder: "Digite seu nome",
    input_2_label: "Sobrenome",
    input_2_placeholder: "Digite seu sobrenome",
    input_3_label: "E-mail",
    input_3_placeholder: "Digite seu e-mail",
    input_4_label: "País",
    input_4_placeholder: "Selecione seu país",
    input_5_label: "Estado",
    input_5_placeholder: "UF",
    input_6_label: "Cidade",
    input_6_placeholder: "Digite sua cidade",
    submit_button: "Confirmar",


    // Stats
    stats_title: "Nosso objetivo está cada vez mais próximo",
    stat_1: "Pessoas",
    stat_2: "Ave-Marias",
    stat_3: "Países",

    // Steps
    step_1: "Ave-Marias rezadas",
    step_2: "Agendar",
    step_3: "Compartilhe",

    // CTA Schedulle
    cta_schedulle_head: "Você deseja continuar rezando pela paz na Ucrânia diariamente?",
    cta_schedulle_button_no: "Agora não",
    cta_schedulle_button_yes: "Sim. Como faço?",
    thanks_input_1_label: "Selecione o total de Ave-Marias que você rezará diariamente",
    thanks_input_2_label: "Até quando você vai rezar pela Ucrânia?",
    thanks_input_2_helper: "Clique no quadrado acima",

    // CTA Share
    cta_share_helper_message_schedulle: "Até o dia <strong>__date__</strong> o sistema vai computar diariamente <strong>__hail_maries__</strong> Ave-Marias para você",
    thanks_share_cta: "Compartilhe essa iniciativa",
    thanks_share_cta_label: "Use a hashtag <b>#standWithUkraine</b> nas suas postagens",

    //Footer
    right: "Todos os direitos reservados",
    header_1: "Contra-Revolução",
    text_1: "Sobre nós",
    text_2: "Contate-nos",
    text_3: "Associações co-irmãs",
    header_2: "Suporte",
    text_4: "Política de privacidade",
    header_3: "Siga nos",
    button: "Confirmar",
}