export default {
    title: "Молитва за Україну",
    p1: "В р. 1990 професор і лідер католицький Плініо Kорреа де Олівеіра за посередництвом бразилійського ТФП і повязаних з ним різних предметів на весь світ, випромував петицію яка нараховує 5 000 000 підписів, закликаючи до визволення Литви з комуністичного ярма. Була це найбільша петиція в історії, котра причинилась не тільки до визволення цієй держави, але також кінця СССР.",
    p2: "Сьогодні ІПЦО, з подібними підметами з цілого світу промує молитовну кампаню за наших католицьких братів і всіх Українців, котрим наново загрожує комуністичне ярмо, а більшість з них віддає своїм життям.",
    p3: "Нашею задачєю є збирати 5 000 000 молитов „Богородице Діво” у всьому світі, прохаючи Пресвяту Богородицю за волю для України від війни і ярма комуністичного.",

    // Navbar
    ipco_logo: "Логотип для Instituto Plinio Corrêa de Oliveira",

    //Form
    toast_error_title: "Помилка збереження Вітаю Маріє",
    toast_sucess_title: "Ave-Marias успішно зареєструвався",
    toast_sucess_description: "Дані будуть оновлені на сайті миттєво.",

    form_heading: "Скільки ти молився?",
    form_t1: "Заповніть форму відповідно до «Богородице Діво», про яку ви молилися. Поверніться на цей сайт і заповніть, коли будете молитися більше.",
    form_t2: "Виберіть загальну кількість уже помолених «Богородице Діво»",

    input_1_label: "Ім’я",
    input_1_placeholder: "Введіть своє ім\'я",
    input_2_label: "Призвище",
    input_2_placeholder: "Введіть своє прізвище",
    input_3_label: "Адрес е-маіл",
    input_3_placeholder: "Введіть свою електронну пошту",
    input_4_label: "Країна",
    input_4_placeholder: "вибрати країну",
    input_5_label: "",
    input_5_placeholder: "",
    input_6_label: "Місто",
    input_6_placeholder: "Введіть своє місто",
    submit_button: "Надіслати",


    // Stats
    stats_title: "Наша мета стає все ближче",
    stat_1: "Люди",
    stat_2: "Богородице Діво",
    stat_3: "Держави",

    // Steps
    step_1: "Радуйся, Маріє помолилася",
    step_2: "Планувати",
    step_3: "Поділіться",

    // CTA Schedulle
    cta_schedulle_head: "Чи бажаєте Ви продовжувати щоденно молитися за мир в Україні?",
    cta_schedulle_button_no: "Не зараз",
    cta_schedulle_button_yes: "Так. Як мені вчинити?",
    thanks_input_1_label: "Виберіть загальну кількість «Вітаю Маріє», яку ви говорите щодня",
    thanks_input_2_label: "Як довго ви будете молитися за Україну?",
    thanks_input_2_helper: "Натисніть на квадрат вище",

    // CTA Share
    cta_share_helper_message_schedulle: "До дня <strong>__date__</strong> система щоденно обчислюватиме для вас <strong>__hail_maries__</strong> Ave-Marias",
    thanks_share_cta: "Поділіться цією ініціативою",
    thanks_share_cta_label: "Використовуйте хештег <b>#standWithUkraine</b> у своїх публікаціях",

    //Footer
    right: "Усі права захищено",
    header_1: "Контрреволюція",
    text_1: "Про нас",
    text_2: "ᴈв\'яжіться з нами",
    text_3: "Побратимські організації",
    header_2: "Підтримка",
    text_4: "Політика конфіденційності",
    header_3: "Слідуй за нами",
    button: "Надіслати",
}