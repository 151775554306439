export default {
    title: "Молитва за Украину",
    p1: "В 1990 году профессор и католический лидер доктор Плинио Корреа де Оливейра посредством бразильской  организации TFP (Tradition Family Property) и связанных с ней структур по всему миру, продвигал петицию собравшую более чем 5 000 000 подписей, призывающую освободить Литву от коммунистического ига. Это была самая большая петиция в истории, которая способствовала не только освобождению этой страны, но и прекращению существования СССР.",
    p2: "Сегодня IPCO (Plinio Corrêa de Oliveira Institute), наряду с аналогичными организациями по всему миру, проводит молитвенную кампанию за наших братьев-католиков и всех украинцев, которым снова угрожает коммунистическое иго, многие из которых жертвуют собственной жизнью.",
    p3: "Наша цель - достичь 5 000 000 молитв \"Радуйся Мария\" (Ave Maria) по всему миру, прося Богородицу освободить Украину от войны и коммунистического ига.",

    // Navbar
    ipco_logo: "Logo do Instituto Plinio Corrêa de Oliveira",

    //Form
    toast_error_title: "Ошибка сохранения «Радуйся, Мария»",
    toast_sucess_title: "Ave-Marias успешно зарегистрирована",
    toast_sucess_description: "Данные будут обновлены на сайте в ближайшее время.",

    form_heading: "Сколько вы молились?",
    form_t1: "Выполните форму в соответствии с тем, сколько \"Радуйся Мария\" вы помолились. Вернитесь на эту страницу и заполните ее снова, если вы помолились больше.",
    form_t2: "Выберите общее количество уже произнесенных «Радуйся, Мария»",

    input_1_label: "Имя",
    input_1_placeholder: "Введите свое имя",
    input_2_label: "Фамилия",
    input_2_placeholder: "Введите вашу фамилию",
    input_3_label: "Адрес e-mail",
    input_3_placeholder: "Введите адрес электронной почты",
    input_4_label: "Страна",
    input_4_placeholder: "выберите страну",
    input_5_label: "",
    input_5_placeholder: "",
    input_6_label: "Город",
    input_6_placeholder: "Введите свой город",
    submit_button: "Отправлять",


    // Stats
    stats_title: "Наша цель становится ближе",
    stat_1: "Люди",
    stat_2: "Радуйся Мария",
    stat_3: "Страны",

    // Steps
    step_1: "Богородица молилась",
    step_2: "Планировать",
    step_3: "Делиться",

    // CTA Schedulle
    cta_schedulle_head: "Желаете ли вы продолжать ежедневно молиться за мир в Украине?",
    cta_schedulle_button_no: "Не сейчас",
    cta_schedulle_button_yes: "Да. Как мне это сделать?",
    thanks_input_1_label: "Выберите общее количество «Радуйся, Мария», которое вы произносите ежедневно.",
    thanks_input_2_label: "Как долго вы будете молиться за Украину?",
    thanks_input_2_helper: "Нажмите на квадрат выше",

    // CTA Share
    cta_share_helper_message_schedulle: "До дня <strong>__date__</strong> система будет ежедневно вычислять для вас <strong>__hail_maries__</strong> Ave-Marias",
    thanks_share_cta: "Поделитесь этой инициативой",
    thanks_share_cta_label: "Используйте хэштег <b>#standWithUkraine</b> в своих сообщениях",

    //Footer
    right: "Bсе права защищены",
    header_1: "контрреволюция",
    text_1: "О нас",
    text_2: "Связаться с нами",
    text_3: "Сестринские ассоциации",
    header_2: "Служба поддержки",
    text_4: "Политика конфиденциальности",
    header_3: "Подписывайтесь на нас",
    button: "Отправлять",
}