export default {
    title: "Prega per l'Ucraina",
    p1: "Nel 1990, il professor Plinio Corrêa de Oliveira, professore e leader cattolico, attraverso la TFP brasiliana e le entità correlate in tutto il mondo, ha promosso una petizione di oltre 5.000.000 di firme per chiedere la libertà della Lituania dal giogo comunista. Fu la più grande petizione della storia, che contribuì alla conquista, non solo della libertà di quel paese, ma anche della fine dell'URSS.",
    p2: "Oggi l'IPCO, insieme ad entità simili e sorelle di tutto il mondo, promuove una campagna di preghiera per i nostri fratelli cattolici e per tutti gli ucraini, che sono nuovamente minacciati dal giogo comunista, molti dei quali sacrificano la propria vita.",
    p3: "Il nostro obiettivo è raggiungere le 5.000.000 di Ave Maria pregate in tutto il mondo, chiedendo alla Madonna di liberare l'Ucraina dalla guerra e dal giogo comunista.",

    // Navbar
    ipco_logo: "Logo dell'Istituto Plinio Corrêa de Oliveira",

    // Form
    toast_error_title: "Errore durante il salvataggio dell'Ave Maria",
    toast_sucess_title: "Ave-Marias è stata registrata con successos",
    toast_sucess_description: "I dati verranno aggiornati sul sito in un attimo.",
    form_heading: "Quanto hai pregato?",
    form_t1: "Compila il modulo secondo le Ave Maria che hai già pregato. Torna su quel sito e compilalo quando preghi di più.",
    form_t2: "Seleziona il numero totale di Ave Maria già dette",
    input_1_label: "Nome di battesimo",
    input_1_placeholder: "Inserisci il tuo nome",
    input_2_label: "Cognome",
    input_2_placeholder: "Inserire il cognome",
    input_3_label: "E-mail",
    input_3_placeholder: "Digita la tua e-mail",
    input_4_label: "Paese",
    input_4_placeholder: "Seleziona il tuo paese",
    input_5_label: "Stato",
    input_6_label: "Città",
    input_6_placeholder: "Inserisci la tua città",
    submit_button: "Confermare",

    // Stats
    stats_title: "Il nostro obiettivo è sempre più vicino",
    stat_1: "Le persone",
    stat_2: "Ave Maria",
    stat_3: "Paesi",

    // Steps
    step_1: "Ave Maria pregata",
    step_2: "Per pianificare",
    step_3: "Condividere",

    // CTA Schedulle
    cta_schedulle_head: "Vuoi continuare a pregare quotidianamente per la pace in Ucraina?",
    cta_schedulle_button_no: "Non adesso",
    cta_schedulle_button_yes: "Sì. Come lo faccio?",
    thanks_input_1_label: "Seleziona il numero totale di Ave Maria che dirai ogni giorno",
    thanks_input_2_label: "Per quanto tempo pregherai per l'Ucraina?",
    thanks_input_2_helper: "Clicca sul quadrato in alto",

    // CTA Share
    cta_share_helper_message_schedulle: "Fino al giorno <strong>__date__</strong> il sistema calcolerà quotidianamente le <strong>__hail_maries__</strong> Ave Maria per te",
    thanks_share_cta: "Condividi questa iniziativa",
    thanks_share_cta_label: "Usa l\'hashtag <b>#standWithUkraine</b> nei tuoi post",

    // Footer
    right: "Tutti i diritti riservati",
    header_1: "Controrivoluzione",
    text_1: "Chi siamo",
    text_2: "Contattaci",
    text_3: "associazioni di co-firma",
    header_2: "Supporto",
    text_4: "Politica sulla riservatezza",
    header_3: "Seguici",
    button: "Confermare",
}