export default {
    title: "Priez pour l'Ukraine",
    p1: "En 1990, le professeur et dirigeant catholique Dr Plinio Corrêa de Oliveira, par l'intermédiaire du TFP brésilien et d'entités apparentées dans le monde, a promu une pétition de plus de 5 000 000 de signatures appelant à la libération de la Lituanie du joug communiste. C'était la plus grande pétition de l'histoire, qui a contribué à la conquête, non seulement de la liberté de ce pays, mais aussi de la fin de l'URSS",
    p2: "Aujourd'hui, IPCO, avec des entités similaires et des co-sœurs du monde entier, promeut une campagne de prière pour nos frères catholiques et pour tous les Ukrainiens qui sont à nouveau menacés par le joug communiste et beaucoup sacrifient leur propre vie.",
    p3: "Notre objectif est d'atteindre 5 000 000 d'Ave Maria priés dans le monde entier, demandant à Notre-Dame de libérer l'Ukraine de la guerre et du joug communiste.",

    // Navbar
    ipco_logo: "Logo de l'Institut Plinio Corrêa de Oliveira",

    // Form
    toast_error_title: "Erreur lors de l'enregistrement de l'Ave Maria",
    toast_sucess_title: "Les Ave Maria enregistrés avec succès",
    toast_sucess_description: "Les données seront mises à jour sur le site dans un instant.",
    form_heading: "Combien avez-vous prié ?",
    form_t1: "Remplissez le formulaire en fonction des Ave Maria que vous avez déjà recités. Retournez sur ce site et remplissez-le lorsque vous priez davantage.",
    form_t2: "Sélectionnez le nombre total d'Ave Maria déjà recités",
    input_1_label: "Prénom",
    input_1_placeholder: "Entrez votre prénom",
    input_2_label: "Nom de famille",
    input_2_placeholder: "Entrez votre nom de famille",
    input_3_label: "E-mail",
    input_3_placeholder: "Entrez votre E-mail",
    input_4_label: "Pays",
    input_4_placeholder: "Sélectionnez votre pays",
    input_5_label: "Etat",
    input_6_label: "Ville",
    input_6_placeholder: "Entrez votre ville",
    submit_button: "Confirmer",

    // Stats
    stats_title: "Notre objectif se rapproche",
    stat_1: "Gens",
    stat_2: "Je vous salue Marie",
    stat_3: "Des pays",

    // Steps
    step_1: "Je vous salue Marie a prié",
    step_2: "Pour planifier",
    step_3: "Partager",

    // CTA Schedulle
    cta_schedulle_head: "Souhaitez-vous continuer à prier quotidiennement pour la paix en Ukraine?",
    cta_schedulle_button_no: "Pas maintenant",
    cta_schedulle_button_yes: "Oui. Comment fais-je?",
    thanks_input_1_label: "Sélectionnez le nombre total de Je vous salue Marie que vous direz quotidiennement",
    thanks_input_2_label: "Combien de temps allez-vous prier pour l'Ukraine?",
    thanks_input_2_helper: "Cliquez sur le carré ci-dessus",

    // CTA Share
    cta_share_helper_message_schedulle: "Jusqu'au jour <strong>__date__</strong> le système calculera quotidiennement <strong>__hail_maries__</strong> Ave-Marias pour vous",
    thanks_share_cta: "Partagez cette initiative",
    thanks_share_cta_label: "Utilisez le hashtag <b>#standWithUkraine</b> dans vos messages",

    // Footer
    right: "Tous les droits sont réservés",
    header_1: "Contre-Révolution",
    text_1: "À propos de nous",
    text_2: "Nous contacter",
    text_3: "Associations-soeurs",
    header_2: "Support",
    text_4: "Politique de confidentialité",
    header_3: "Suivez-nous",
    button: "Confirmer",
}