import {createContext, useContext, useEffect, useState} from "react";
import {useRouter} from "next/router";
import ptBR from "../locales/pt-BR";
import es from "../locales/es";
import it from "../locales/it";
import fr from "../locales/fr";
import de from "../locales/de";
import en from "../locales/en";
import pl from "../locales/pl";
import uk from "../locales/uk";
import ru from "../locales/ru";

const LanguageContext = createContext({})

export function LanguageProvider({children}) {
    const router = useRouter()
    const {locale} = router

    const getFile = () => {
        if (locale === "pt-BR")
            return ptBR
        if (locale === "es")
            return es
        if (locale === "it")
            return it
        if (locale === "fr")
            return fr
        if (locale === "de")
            return de
        if (locale === "tl-PH")
            return tlPH
        if (locale === "pl")
            return pl
        if (locale === 'uk')
            return uk
        if (locale === 'ru')
            return ru
        return en
    }

    const [text, setText] = useState(getFile())

    useEffect(() => {
        setText(getFile())
    }, [locale])
    return (
        <LanguageContext.Provider value={{
            text,
            locale
        }}>
            {children}
        </LanguageContext.Provider>
    )
}

export function useLanguage() {
    return useContext(LanguageContext)
}