export default {
    title: "Bete für die Ukraine",
    p1: "1990 förderte der Professor und katholische Führer Dr. Plinio Corrêa de Oliveira über die brasilianische TFP und verwandte Einrichtungen auf der ganzen Welt eine Petition mit mehr als 5.000.000 Unterschriften, in der die Befreiung Litauens vom kommunistischen Joch gefordert wurde. Es war die größte Petition in der Geschichte, die nicht nur zur Eroberung der Freiheit dieses Landes, sondern auch zum Ende der UdSSR beitrug.",
    p2: "Heute fördert IPCO zusammen mit ähnlichen Einrichtungen und Mitschwestern aus der ganzen Welt eine Gebetskampagne für unsere katholischen Brüder und für alle Ukrainer, die erneut vom kommunistischen Joch bedroht werden, von denen viele ihr eigenes Leben opfern.",
    p3: "Unser Ziel ist es, 5.000.000 Ave-Marias zu erreichen, die auf der ganzen Welt gebetet haben und die Muttergottes bitten, die Ukraine vom Krieg und dem kommunistischen Joch zu befreien.",

    // Navbar
    ipco_logo: "Logo des Instituts Plinio Corrêa de Oliveira",

    // Form
    toast_error_title: "Fehler beim Speichern von Ave Marys",
    toast_sucess_title: "Ave-Marias erfolgreich registriert",
    toast_sucess_description: "Die Daten werden in Kürze auf der Website aktualisiert.",
    form_heading: "Wie viel hast du gebetet?",
    form_t1: "Füllen Sie das Formular entsprechend den Ave Marys aus, die Sie bereits gebetet haben. Gehen Sie zurück zu dieser Seite und füllen Sie sie aus, wenn Sie mehr beten.",
    form_t2: "Wählen Sie die Gesamtzahl der bereits gesprochenen Ave Marys aus",
    input_1_label: "Vorname",
    input_1_placeholder: "Gib deinen Namen ein",
    input_2_label: "Familienname, Nachname",
    input_2_placeholder: "Geben Sie Ihren Nachnamen ein",
    input_3_label: "Email",
    input_3_placeholder: "Geben sie ihre E-Mail Adresse ein",
    input_4_label: "Eltern",
    input_4_placeholder: "Wähle dein Land",
    input_5_label: "Zustand",
    input_6_label: "Stadt",
    input_6_placeholder: "Geben Sie Ihre Stadt ein",
    submit_button: "Bestätigen Sie",

    // Stats
    stats_title: "Unser Ziel rückt näher",
    stat_1: "Menschen",
    stat_2: "Heil Marys",
    stat_3: "Länder",

    // Steps
    step_1: "Ave Marys betete",
    step_2: "Planen",
    step_3: "Teilen",

    // CTA Schedulle
    cta_schedulle_head: "Möchten Sie weiterhin täglich für den Frieden in der Ukraine beten?",
    cta_schedulle_button_no: "Nicht jetzt",
    cta_schedulle_button_yes: "Ja. Wie mache ich?",
    thanks_input_1_label: "Wählen Sie die Gesamtzahl der Ave Marys aus, die Sie täglich sagen werden",
    thanks_input_2_label: "Wie lange werden Sie für die Ukraine beten?",
    thanks_input_2_helper: "Klicken Sie oben auf das Quadrat",

    // CTA Share
    cta_share_helper_message_schedulle: "Bis zum Tag <strong>__date__</strong> berechnet das System täglich <strong>__hail_maries__</strong> Ave-Marias für Sie",
    thanks_share_cta: "Teilen Sie diese Initiative",
    thanks_share_cta_label: "Verwenden Sie in Ihren Posts den Hashtag <b>#standWithUkraine</b>",

    // Footer
    right: "Alle Rechte vorbehalten",
    header_1: "Gegenrevolution",
    text_1: "Über uns",
    text_2: "kontaktiere uns",
    text_3: "Co-Firmenverbände",
    header_2: "Unterstützung",
    text_4: "Datenschutz-Bestimmungen",
    header_3: "Folge uns",
    button: "Bestätigen Sie",
}