export default {
    title: "Pray for Ukraine",
    p1: "In 1990, Professor and Catholic leader Dr. Plinio Corrêa de Oliveira, through the Brazilian TFP and related entities around the world, promoted a petition of more than 5,000,000 signatures calling for the freedom of Lithuania from the communist yoke. It was the largest petition in history, which contributed to the conquest, not only of the freedom of that country, but also of the end of the USSR.",
    p2: "Today, IPCO, together with similar entities from around the world, promotes a prayer campaign for our Catholic brothers and for all Ukrainians, who are being threatened again by the communist yoke, many of them sacrificing their own lives.",
    p3: "Our goal is to reach 5,000,000 Hail Marys prayed all over the world, asking Our Lady to free Ukraine from war and the communist yoke.",


    // Navbar
    brazilian_flag_description: "Brazilian flag",
    american_flag_description: "American flag",
    spanish_flag_description: "spanish flag",
    france_flag_description: "france flag",
    german_flag_description: "german flag",
    italian_flag_description: "italian flag",
    ipco_logo: "Plinio Corrêa de Oliveira Institute Logo",


    // Form
    toast_error_title: "Error saving Hail Marys",
    toast_sucess_title: "Hail Marys successfully registered",
    toast_sucess_description: "The data will be updated on the website in a moment",

    form_heading: "How much have you prayed?",
    form_t1: "Fill out the form according to the Hail Marys you have prayed. Go back to that site and fill in when you pray more.",
    form_t2: "Select the total of Hail Marys already prayed",

    input_1_label: "First name",
    input_2_label: "Last name",
    input_3_label: "Email address",
    input_4_label: "Country",
    input_4_placeholder: "Select a country",
    input_5_label: "State",
    input_6_label: "City",
    input_6_placeholder: "Type your city",
    submit_button: "Submit",

    // Stats
    stats_title: "Our goal is getting closer",
    stat_1: "People",
    stat_2: "Hail-Marys",
    stat_3: "Countries",

    // Steps
    step_1: "Hail Marys prayed",
    step_2: "To schedulle",
    step_3: "Share",


    // CTA Schedulle
    cta_schedulle_head: "Do you wish to continue praying for peace in Ukraine daily?",
    cta_schedulle_button_no: "Not now",
    cta_schedulle_button_yes: "Yes. How do I do?",
    thanks_input_1_label: "Select the total number of Hail Marys you will say daily",
    thanks_input_2_label: "How long will you pray for Ukraine?",
    thanks_input_2_helper: "Click on the square above",

    // CTA Share
    cta_share_helper_message_schedulle: "Until the day <strong>__date__</strong> the system will compute daily <strong>__hail_maries__</strong> Ave-Marias for you.",
    thanks_share_cta: "Share this initiative",
    thanks_share_cta_label: "Use the hashtag <b>#standWithUkraine</b> in your posts",


    // Footer
    right: "All rights reserved",
    header_1: "Counter-Revolution",
    text_1: "About us",
    text_2: "Contact us",
    text_3: "Co-sister associations",
    header_2: "Support",
    text_4: "Privacy Policy",
    header_3: "Follow us",
    button: "Send",
}