import { GrowthBook, GrowthBookProvider } from "@growthbook/growthbook-react";
import type { AppProps } from 'next/app';
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect } from "react";
import { LanguageProvider } from "./../hooks/useLanguage";

import { ChakraProvider } from "@chakra-ui/provider";
import { Box, createStandaloneToast, extendTheme } from "@chakra-ui/react";

import { GTM_ID, pageview } from "../lib/googleTagManager";
import { ipcoTheme } from "../lib/ipcoTheme";

import { Footer } from "../components/Footer";
import { Navbar } from "../components/Navbar";
import { getFrontendAPIClient } from "../lib/api";

const growthbook = new GrowthBook({
    trackingCallback: (experiment, result) => {

        const api = getFrontendAPIClient()
        api.post('/ab', {
            ab: result.variationId ? 'a' : 'b'
        })

        console.log({
            experimentId: experiment.key,
            variationId: result.variationId,
            ab: result.variationId ? 'a' : 'b'
        })
    }
});

function MyApp({ Component, pageProps }: AppProps) {
    const theme = extendTheme(ipcoTheme)
    const router = useRouter()
    useEffect(() => {
        router.events.on('routeChangeComplete', pageview)
        return () => {
            router.events.off('routeChangeComplete', pageview)
        }
    }, [router.events])
    const { ToastContainer } = createStandaloneToast()

    // useEffect(() => {
    //     // Load feature definitions from API
    //     fetch(process.env.NEXT_PUBLIC_GROWTHBOOK_KEY || '')
    //         .then((res) => res.json())
    //         .then((json) => {
    //             growthbook.setFeatures(json.features);
    //         });

    //     const { 'rezecontraocomunismo@db': cookieText } = parseCookies()
    //     const cookie = JSON.parse(cookieText)

    //     const { 'rezecontraocomunismo@geo': cookieGeoText } = parseCookies()
    //     const cookieGeo = JSON.parse(cookieGeoText)

    //     growthbook.setAttributes({
    //         "id": cookie.utm_id,
    //         "country": cookieGeo.country
    //     })
    // }, [])

    return <>
        <Script
            id={"google-tag-manager"}
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','${GTM_ID}');
                `
            }}
        />
        <GrowthBookProvider growthbook={growthbook}>
            <ChakraProvider theme={theme}>
                <LanguageProvider>
                    <Navbar />
                    <Box m={0} p={0} minH={'80vh'}>
                        <Component {...pageProps} />
                        <ToastContainer />
                    </Box>
                    <Footer />
                </LanguageProvider>
            </ChakraProvider>
        </GrowthBookProvider>
    </>
}

export default MyApp
