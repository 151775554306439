
const activeLabelStyles = {
    transform: "scale(0.825) translateY(-24px) translateX(-10px)"
};

export const ipcoTheme = {
    colors: {
        yellow: {
            "base": "#E3AA26"
        }, blue: {
            "500": "#053C5E", "600": "#194e86", "base": "#053C5E"
        }, red: {
            "base": "#991A1C"
        }, gray: {
            "base": "#E5E5E5"
        },
    }, fonts: {
        heading: "Roboto Slab, serif", body: "Roboto Slab",
    },
    components: {
        Form: {
            variants: {
                floating: {
                    container: {
                        _focusWithin: {
                            label: {
                                ...activeLabelStyles,
                            },
                        },
                        "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label": {
                            ...activeLabelStyles
                        },
                        label: {
                            top: 0,
                            left: 0,
                            zIndex: 2,
                            position: "absolute",
                            backgroundColor: "white",
                            color: 'gray.600',
                            mx: 1,
                            px: 1,
                            my: 2,
                        },
                    },
                },
            },
        },
    },
}