export default {
    title: "Recemos por Ucrania",
    p1: "En 1990, el profesor y líder católico Dr. Plinio Corrêa de Oliveira, a través de la TFP brasileña y entidades afines en todo el mundo, promovió una petición de más de 5.000.000 de firmas pidiendo la liberación de Lituania del yugo comunista. Fue la petición más grande de la historia, que contribuyó a la conquista, no solo de la libertad de ese país, sino también del fin de la URSS.",
    p2: "Hoy, IPCO, junto con entidades similares y co-hermanas de todo el mundo, promueve una campaña de oración por nuestros hermanos católicos y por todos los ucranianos, que están siendo amenazados nuevamente por el yugo comunista, muchos de elllos sacrificando sus propias vidas.",
    p3: "Nuestro objetivo es llegar a 5.000.000 de Avemarías rezadas en todo el mundo, pidiendo a Nuestra Señora que libere a Ucrania de la guerra y del yugo comunista.",

    // Navbar
    brazilian_flag_description: "Bandera de Brasil",
    american_flag_description: "Bandera de los Estados Unidos",
    spanish_flag_description: "bandera española",
    france_flag_description: "bandera de francia",
    german_flag_description: "bandera de alemania",
    italian_flag_description: "bandera italiana",
    ipco_logo: "Logotipo del Instituto Plinio Corrêa de Oliveira",

    // Form
    toast_error_title: "Error al guardar Avemarías",
    toast_sucess_title: "Ave Marías registradad con éxito",
    toast_sucess_description: "Los datos se actualizarán en el sitio web en un momento.",
    form_heading: "¿Cuántas has rezado?",
    form_t1: "Rellena el formulario según las Avemarías que ya has rezado. Regresa a este sitio y complétalo cuando ya hayas rezado más.",
    form_t2: "Seleccione el número total de Avemarías ya rezadas",
    input_1_label: "Nombre (s)",
    input_1_placeholder: "Ingresa tu nombre",
    input_2_label: "Apelido (s)",
    input_2_placeholder: "Ingresa tu apelido",
    input_3_label: "Correo electrónico",
    input_3_placeholder: "Ingresa tu correo elctrónico",
    input_4_label: "País",
    input_4_placeholder: "Selecciona tu país",
    input_5_label: "Estado",
    input_6_label: "Ciudad",
    input_6_placeholder: "Ingresa tu ciudad",
    submit_button: "Confirmar",

    // Stats
    stats_title: "Nuestro objetivo está cada vez más cerca",
    stat_1: "Personas",
    stat_2: "Avemarías",
    stat_3: "Países",

    // Steps
    step_1: "Avemarías rezadas",
    step_2: "Para programar",
    step_3: "Cuota",

    // CTA Schedulle
    cta_schedulle_head: "¿Desea continuar rezando por la paz en Ucrania todos los días?",
    cta_schedulle_button_no: "Ahora no",
    cta_schedulle_button_yes: "Sí. ¿Como hago?",
    thanks_input_1_label: "Seleccione el número total de Avemarías que dirá diariamente",
    thanks_input_2_label: "¿Cuánto tiempo orarás por Ucrania?",
    thanks_input_2_helper: "Haga clic en el cuadrado de arriba",

    // CTA Share
    cta_share_helper_message_schedulle: "Hasta el día <strong>__date__</strong> el sistema calculará diariamente <strong>__hail_maries__</strong> Ave Marías para usted",
    thanks_share_cta: "Comparte esta iniciativa",
    thanks_share_cta_label: "Usa el hashtag <b>#standWithUkraine</b> en tus publicaciones.",

    // Footer
    right: "Todos los derechos reservados",
    header_1: "Contrarrevolución",
    text_1: "Sobre nosotros",
    text_2: "Contáctenos",
    text_3: "Asociaciones co-hermanas e afines",
    header_2: "Soporte",
    text_4: "Política de privacidad",
    header_3: "Síguenos",
    button: "Confirmar",
}