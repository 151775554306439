import { Box } from "@chakra-ui/react";
import Image from "next/image";
import { useEffect, useState } from "react";
import logoBannerPic from "../../../public/logo-banner.png";

export function Navbar() {
    const [isScroled, setIsScroled] = useState(true)
    const [isChanged, setIsChanged] = useState(false)

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 150 && !isChanged) {
                setIsChanged(true)
                setIsScroled(true)
                return
            }

            if (window.scrollY < 100) {
                setIsChanged(false)
                setIsScroled(false)
            }
        }
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <Box
            w={"100%"}
            bgColor={"blue.base"}
            maxH={'auto'}
            textAlign={"center"}
            p={"10px"}
            mb={8}
            top="0"
            position={'sticky'}
            zIndex={"sticky"}
            boxShadow="10px 10px 50px #ccc"

        >
            <Box
                w={isScroled === true ? '260px' : '480px'}
                maxW={"100%"}
                transition="all 0.5s ease"
                h={isScroled === true ? `50px` : 'auto'}
                mx={"auto"}
            >
                <Image
                    src={logoBannerPic}
                    placeholder={"blur"}
                    blurDataURL={"/logo-banner.png"}
                    layout={"responsive"}
                    alt={"Logo do Instituto Plinio Corrêa de Oliveira"}
                />
            </Box>
        </Box>
    );
}
