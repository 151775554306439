export default {
    title: "Modlitwa za Ukrainę",
    p1: "W 1990 r. profesor i lider katolicki dr Plinio Corrêa de Oliveira, za pośrednictwem brazylijskiego TFP i powiązanych z nim podmiotów na całym świecie, wypromował petycję złożoną z ponad 5 000 000 podpisów, wzywającą do uwolnienia Litwy spod jarzma komunistycznego. Była to największa petycja w historii, która przyczyniła się nie tylko do wyzwolenia tego kraju, ale także do końca ZSRR. ",
    p2: "Dziś IPCO, wraz z podobnymi podmiotami z całego świata, promuje kampanię modlitewną za naszych katolickich braci i wszystkich Ukraińców, którym ponownie zagraża komunistyczne jarzmo, a wielu z nich poświęca swoje życie. ",
    p3: "Naszym celem jest osiągnąć 5 000 000 modlitw „Zdrowaś Maryjo” na całym świecie, prosząc Matkę Bożą o uwolnienie Ukrainy od wojny i komunistycznego jarzma. ",


    // Navbar
    ipco_logo: "Logo Instytutu Plinio Correâ de Oliveira (IPCO)",


    // Form
    toast_error_title: "Błąd podczas zapisywania Zdrowaś Maryjo",
    toast_sucess_title: "Zdrowaś Maryjo uratowane pomyślnie",
    toast_sucess_description: "Licznik Zdrowaś Maryjo zostanie zaktualizowany za chwilę",

    form_heading: "Ile się modliłeś?",
    form_t1: "Wypełnij formularz według tego, ile „Zdrowaś Maryjo” odmówiłeś. Wróć na tę stronę i wypełnij ponownie, jeśli będziesz się modlić więcej. ",
    form_t2: "Wybierz sumę odmówionych już „Zdrowaś Maryjo”",

    input_1_label: "Imię",
    input_2_label: "Nazwisko",
    input_3_label: "Adres e-mail",
    input_4_label: "Kraj",
    input_4_placeholder: "Wybierz kraj",
    input_5_label: "Wybierz kraj",
    input_6_label: "Miasto",
    input_6_placeholder: "Wpisz swoje miasto",
    submit_button: "Wysyłać",

    // Stats
    stats_title: "Nasz cel jest coraz bliżej",
    stat_1: "Ludzie",
    stat_2: "Zdrowaś Maryjo",
    stat_3: "Kraje",

    // Steps
    step_1: "Odmówione Zdrowaś Maryjo",
    step_2: "Zaplanuj",
    step_3: "Udostępnij",

    // CTA Schedulle
    cta_schedulle_head: "Czy chcesz kontynuować codzienną modlitwę za Ukrainę?",
    cta_schedulle_button_no: "Nie teraz",
    cta_schedulle_button_yes: "Tak. Jak to zrobić?",
    thanks_input_1_label: "Wybierz sumę Zdrowaś Maryjo, jaką będziesz odmawiać codziennie",
    thanks_input_2_label: "Do kiedy będziesz się modlić za Ukrainę?",
    thanks_input_2_helper: "Kliknij w powyższy kwadrat",

    // CTA Share
    cta_share_helper_message_schedulle: "Do dnia <strong>__date__</strong> system będzie codziennie wyliczał <strong>__hail_maries__</strong> Zdrowaś Maryjo dla Ciebie",
    thanks_share_cta: "Podziel się tą inicjatywą",
    thanks_share_cta_label: "Użyj hashtaga <b>#standWithUkraine</b> w swoich postach",

    // Footer
    right: "Wszelkie prawa zastrzeżone ",
    header_1: "Kontrrewolucja",
    text_1: "O nas",
    text_2: "Skontaktuj się z nami ",
    text_3: "Stowarzyszenia siostrzane ",
    header_2: "Wsparcie",
    text_4: "Polityka prywatności ",
    header_3: "Podążaj za nami ",
    button: "Wysyłać",
}