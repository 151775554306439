import Image from "next/image";
import NextLink from "next/link";

import { Box, Container, Link, SimpleGrid, Stack, Text } from '@chakra-ui/react';

const ListHeader = ({ children }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    );
};

export function Footer() {

    const now = new Date()
    const year = now.getFullYear()

    return (
        <Box
            bg={'gray.50'}
            color={'gray.700'}
            mt={80}
        >
            <Container as={Stack} maxW={'6xl'} py={10}>
                <SimpleGrid
                    templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 1fr 1fr' }}
                    spacing={8}>
                    <Stack spacing={6}>
                        <Box display={"flex"} alignItems={"center"}>
                            <Image
                                src={"/logo.png"}
                                width={"50px"}
                                height={"50px"}
                                alt={"Logo do Instituto Plinio Corrêa de Oliveira"}
                            />
                            <Text ml={2}>Instituto Plinio Corrêa de Oliveira</Text>
                        </Box>
                        <Text fontSize={'sm'}>
                            © {year} - Todos os direitos reservados
                        </Text>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Contra-Revolução</ListHeader>
                        <Link href={'https://ipco.org.br/quem-somos/'} isExternal>Quem somos nós</Link>
                        <Link href={'https://ipco.org.br/fale-conosco/'} isExternal>Fale conosco</Link>
                        <Link href={'https://ipco.org.br/quem-somos/o-mundo-de-plinio-correa-de-oliveira'} isExternal>
                            Associações co-irmãs
                        </Link>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Ajuda</ListHeader>
                        <NextLink href={"/privacy"} passHref>
                            <Link>Política de privacidade</Link>
                        </NextLink>
                    </Stack>
                    <Stack align={'flex-start'}>
                        <ListHeader>Siga-nos</ListHeader>
                        <Link href={'https://www.facebook.com/Inst.PCO'} isExternal>Facebook</Link>
                        <Link href={'https://twitter.com/InstitutoPCO'} isExternal>Twitter</Link>
                        <Link href={'https://www.instagram.com/institutopco/'} isExternal>Instagram</Link>
                    </Stack>
                </SimpleGrid>
            </Container>
        </Box>
    );
}